<script setup lang="ts">
import Header from "~~/components/layoutComponents/header/Header.vue";
import {useI18n} from "vue-i18n";
const i18n = useI18n()

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

</script>
<script lang="ts">
export default {
  name: "DefaultPageLayout",
};
</script>
<template>
  <Html
    :lang="i18n.locale.value"
  >
    <Head>
      <Title>{{ title }}</Title>
      <template
        v-for="link in head.link"
        :key="link.id"
      >
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="`https://climatespy.com/${link.href}`"
          :hreflang="link.hreflang"
        />
      </template>
      <template
        v-for="meta in head.meta"
        :key="meta.id"
      >
        <Meta
          :id="meta.id"
          :property="meta.property"
          :content="meta.content"
        />
      </template>
    </Head>
    <v-app>
      <Header />
      <v-main>
        <v-container :fluid="true">
          <slot />
        </v-container>
      </v-main>
    </v-app>
  </Html>
</template>
